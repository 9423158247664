// packages block
import { useState } from 'react';

// components block
import Checkbox from '../../../components/Checkbox';
import CustomButton from '../../../components/layout/CustomButton';
import CandidateSearchItem from './CandidateSearchItem';

// interfaces, graphql, constants block
import InputField from '../../../components/layout/InputField';
import NoDataFound from '../../../components/layout/NoDataFound';
import Pagination from '../../../components/layout/Pagination';
import {
  ADD_TO_TJ_LOOKUP,
  CLEAR_SELECTION,
  MOVE_TO_FOLDER,
  RECORDS_SELECTED,
  SELECT_All,
} from '../../../constants';
import { PAGE_LIMIT } from '../../../utils/constant';
import CandidateRecruiterModal from './CandidateRecruiterModal';
import CandidateTJLookupModal from './CandidateTJLookupModal';

const CandidateSearchList = ({
  data,
  limit,
  setLimit,
  currentPage,
  setCurrentPage,
}) => {
  const [recruiterFolder, setRecruiterFolder] = useState<boolean>(false);
  const [TJLookupModal, setTJLookupModal] = useState<boolean>(false);
  const [selectedCandidates, setSelectedCandidates] = useState<any[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);

  const recruiterFolderToggle = () => setRecruiterFolder(!recruiterFolder);
  const TJLookupModalToggle = () => setTJLookupModal(!TJLookupModal);

  const manageAllSelection = (e: any) => {
    if (e.target.checked) {
      setSelectedCandidates(candidates);
      setAllSelected(true);
    } else {
      setSelectedCandidates([]);
      setAllSelected(false);
    }
  };

  const count = data && data?.allCandidatesAdvanceSearch?.count;
  const candidates = data && data?.allCandidatesAdvanceSearch?.candidates;
  const geoLocDistance = data && data?.allCandidatesAdvanceSearch?.geoLocDiffs;

  return (
    <>
      {candidates?.length ? (
        <div className="candidate-serach-wrap h-100">
          <div className="py-3 px-4">
            <div className="candidate-search-top d-flex flex-wrap align-items-center justify-content-between w-100">
              <div className="d-flex candidate-search-all">
                <Checkbox
                  parentClass="checkbox-square"
                  selectedCandidates={selectedCandidates}
                  allSelected={allSelected}
                  onChange={(e: any) => manageAllSelection(e)}
                />

                <p className="pt-1">{SELECT_All}</p>
              </div>

              <div className="candidate-search-option flex-wrap d-flex align-items-center gap-10">
                <p className="mb-0 mr-2">
                  {selectedCandidates?.length} {RECORDS_SELECTED}
                </p>
                <CustomButton
                  buttonText={CLEAR_SELECTION}
                  buttonColor="primary"
                  buttonType="button"
                  className="graydark-button"
                  buttonClick={() => {
                    setSelectedCandidates([]);
                    setAllSelected(false);
                  }}
                />{' '}
                {/* <CustomButton
                  buttonText={ADD_TO_TJ_LOOKUP}
                  buttonColor="primary"
                  buttonType="button"
                  className="py-2"
                  buttonClick={() =>
                    selectedCandidates?.length && setTJLookupModal(true)
                  }
                />{' '} */}
                <CustomButton
                  buttonText={MOVE_TO_FOLDER}
                  buttonColor="primary"
                  buttonType="button"
                  buttonClick={() =>
                    selectedCandidates?.length && setRecruiterFolder(true)
                  }
                />{' '}
              </div>
            </div>

            <div className="mt-4">
              <div className="candidate-search-itemsList">
                {data &&
                  candidates?.map((candidate, index) => {
                    const geoLoc = geoLocDistance ? geoLocDistance[index] : undefined;
                    return (
                      <CandidateSearchItem
                        candidate={candidate}
                        geoLocDistance={geoLoc}
                        selectedCandidates={selectedCandidates}
                        setSelectedCandidates={setSelectedCandidates}
                        setAllSelected={setAllSelected}
                        key={index}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
          {candidates?.length ? (
            <div className="users-pagination">
              <Pagination
                onPageChange={(pageClicked: number) => {
                  setCurrentPage(pageClicked);
                }}
                pageCount={Math.ceil(count / limit)}
                currentPage={currentPage}
              />

              <div className="d-flex align-items-center">
                <div className="pagination-display paginationItemsSelect">
                  <p className="m-0">
                    {(currentPage - 1) * limit + 1} to{' '}
                    {Math.min(currentPage * limit, count)} of {count}
                  </p>

                  <div className="divider"></div>
                  <label>Display</label>
                  <InputField
                    label=""
                    inputtype="select"
                    selectItems={PAGE_LIMIT.filter(
                      (item) => item.value !== limit
                    )}
                    inputid="limit"
                    placeholder={limit.toString()}
                    inputMethod={(value: string) => {
                      setCurrentPage(1);
                      setLimit(parseInt(value));
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="candidate-serach-wrap h-100">
          <NoDataFound text="No Candidates found" />
        </div>
      )}
      <CandidateRecruiterModal
        isOpen={recruiterFolder}
        toggle={recruiterFolderToggle}
        recordToAdd={selectedCandidates?.map((candidate: any) => candidate?.id)}
        refetch={() => console.log('Refetching candidates')}
        clearRecord={() => console.log('Clearing record')}
      />

      <CandidateTJLookupModal
        isOpen={TJLookupModal}
        toggle={TJLookupModalToggle}
        recordToAdd={selectedCandidates}
        title="Add to Recruiting Sequence"
      />
    </>
  );
};

export default CandidateSearchList;
