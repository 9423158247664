import { gql } from "@apollo/client";

export const ADD_MUTATION = gql`
mutation($input: CreateInvoiceAttachmentInput!,$placementId:String!,$placementStatus:String!) {
  createInvoiceAttachment(placementId:$placementId,createInvoiceAttachmentInput: $input,placementStatus:$placementStatus) {
    message
    status
  }
}`

export const GET_ALL = gql`
query ($placementId:String!) {
  invoiceAttachments(placementId:$placementId) {
    id
    fileName
    activityReport
    attachmentType
    fileUrl
    amount
    receivedDate
    uploadedBy {
      id
      firstName
      lastName
      fullName
    }
    placement {
      id
      placementId
    }
    invoiceEmailHistory{
      id
      emailTo
      emailType
      createdAt
    }
    invoicePreSelectEmails{
      id
      email
      emailType
    }
    createdAt
    updatedAt
  }
}`

export const REMOVE_MUTATION = gql`
mutation($id:String!) {
  removeInvoiceAttachment(id: $id) {
    message
    status
  }
}`

export const UPDATE_MUTATION = gql`
mutation($input: UpdateInvoiceAttachmentInput!,$placementStatus:String!) {
  updateInvoiceAttachment(updateInvoiceAttachmentInput: $input,placementStatus:$placementStatus) {
    message
    status
  }
}`

export const EMAIL_MUTATION = gql`
mutation($input: InvoiceEmailInput!,$additionalFiles:[AdditionalFiles!]) {
  sendEmail(input: $input,additionalFiles:$additionalFiles) {
    message
    status
  }
}`

export const GET_ALL_INVOICE_EMAIL_ATTACHMENTS = gql`
query($id:String!){
  getAllInvoiceEmailAttachments(id:$id){
    id,
    fileName:documentName
    source:documentUrl
  }
}`

export const GET_ALL_COMPANY_CONTACTS_EMAIL = gql`
query($companyId: String!) {
  getAllCompanyContactsEmails(companyId: $companyId) {
    value
    label
  }
}
`